import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import {  persistReducer } from "redux-persist";
import { rootPersistConfig, rootReducer } from "./rootreducer";

// ----------------------------------------------------------------------
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  //devTools: process.env.NODE_ENV !== 'production',
});


const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, dispatch, useSelector, useDispatch };
