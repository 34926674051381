import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  permissionList: [],
  profileList :[],
  profileTitle: "",
  profileIds: [],
};

const permission = createSlice({
  name: "permission",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getPermission(state, action) {
      state.permissionList = action.payload === undefined ? [] : action.payload;
    },
    createPermissionResponse(state, action) {
      state.createProjectId =
        action.payload === undefined ? null : action.payload;
    },
    profileListResponse(state, action) {
      state.profileList = action.payload === undefined ? [] : action.payload;
    },
    profileTitleName(state, action) {
      state.profileTitle = action.payload === undefined ? null : action.payload;
    },
    profileIdsName(state, action) {
      state.profileIds = action.payload === undefined ? [] : action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export default permission.reducer;
export const {
  getPermission,
  createPermissionResponse,
  startLoading,
  stopLoading,
  profileIdsName,profileTitleName,
  profileListResponse,
} = permission.actions;
