import { useRoutes, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";

import AuthGuard from "../guard/Authguard";
import GuestGuard from "../guard/GuestGuard";
import { publicPaths, authProtectedPaths } from "./path";

const NotFound = lazy(() => import("../pages/NotFound"));

const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const DashboardLayout = lazy(() => import("../layout/dashboard"));
const Dashboard = lazy(() => import("../layout/dashboard/Dashboard"));
const ManageUserProjects = lazy(
  () => import("../pages/dashboard/ManageUserProjects")
);
const Primary = lazy(() => import("../workspace/editor/Primary"));
const BpnmEditor = lazy(() => import("../workspace/editor/bpnm/BpnmEditor"));
const DmnEditor = lazy(() => import("../workspace/editor/bpnm/DmnEditor"));

const Settings = lazy(() => import("../pages/dashboard/Settings"));
const ChoosePlan = lazy(() => import("../pages/dashboard/ChoosePlan"));

export default function Router() {
  return useRoutes([
    {
      path: `${publicPaths.login}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard>
            <Login />
          </GuestGuard>
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.register}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <Register />
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.forgotPassword}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <ForgotPassword />{" "}
        </Suspense>
      ),
    },

    {
      path: `${publicPaths.resetPassword}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <ResetPassword />{" "}
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.dashboard}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <Dashboard />
            </Suspense>
          ),
          index: true,
        },
        { path: `${authProtectedPaths.Workspace}`, element: <Primary /> },
        {
          path: `${authProtectedPaths.bpnmworkspace}`,
          element: <BpnmEditor />,
        },
        { path: `${authProtectedPaths.dmnworkspace}`, element: <DmnEditor /> },
      ],
    },
    {
      path: `${authProtectedPaths.ManageUserProjects}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <ManageUserProjects />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.Settings}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <Settings />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: `${authProtectedPaths.ChoosePlan}`,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard>
            <ChoosePlan />
          </AuthGuard>
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <NotFound />{" "}
        </Suspense>
      ),
      children: [
        {
          path: "404",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <NotFound />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <Navigate to="/404" replace />
            </Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    {
      path: "/",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard>
            <Login />
          </GuestGuard>
        </Suspense>
      ),
    },
  ]);
}
