import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectLoading: false,
  ProjectList: [],
  createProjectId: null,
  totalProject: 10,
};

const projects = createSlice({
  name: "projects",
  initialState,
  reducers: {
    startProjectListLoading(state) {
      state.projectLoading = true;
    },
    getProjects(state, action) {
      state.ProjectList = action.payload === undefined ? [] : action.payload;
    },
    createProjectResponse(state, action) {
      state.createProjectId =
        action.payload === undefined ? null : action.payload;
    },
    getTotalProjects(state, action) {
      state.totalProject = action.payload;
    },
    stopProjectListLoading(state) {
      state.projectLoading = false;
    },
  },
});

export default projects.reducer;
export const {
  getProjects,
  createProjectResponse,
  startProjectListLoading,
  stopProjectListLoading,
  getTotalProjects,
} = projects.actions;
