export const addManagePermissionMutation = async (
  username,
  firstName,
  lastName,
  email,
  profileId,
  mobile
) => {
  const response = {
    variables: {
      request: {
        username: username,
        firstName: firstName,
        lastName: lastName,
        email: email,
        profileId: profileId,
        mobile: mobile,
      },
    },
  };
  return response;
};

export const getProfileMutation = async () => {
  const response = {
    variables: {},
  };
  return response;
};

export const updateProfileMutation = (dataToUpdate) => {
  const variables = {
    request: {
      id: dataToUpdate.profileId,
      name: dataToUpdate.profileName,
      toBeAddedPermissionIds: dataToUpdate.addedPermissions,
      toBeRemovedPermissionIds: dataToUpdate.removedPermissions,
    },
  };
  return variables;
};
export const createProfileMutation = async (checkedItems, permissionName) => {
  const response = {
    variables: {
      profile: {
        name: permissionName,
        permissionIds: checkedItems[0],
      },
    },
  };
  return response;
};
