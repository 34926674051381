export const allPagesQuery = () => ({
  query: `query allPages($condition: PageCondition, $orderBy: PagesOrderBy) {
        allPages(condition: $condition, orderBy: $orderBy) {
          totalCount
          edges {
            node {
              id
              projectId
              title
              pageId
            }
          }
        }
      }`,
});

export const createPageQuery = {
  query: `mutation($input: CreatePageInput!) {
      createPage(input: $input) {
        page{
            projectId
            id
            pageId
          title
        }
      }
    }`,
};

export const updatePageQuery = {
  query: `mutation($input: UpdatePageInput!) {
    updatePageByPageId(input: $input) {
      page{
          projectId
          pageId
          id
          initialCreate
          title
      }
    }
  }`,
};
