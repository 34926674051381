export const createPageMutation = (projectId, title) => ({
  input: {
    page: {
      projectId: projectId,
      isDeleted: 0,
      status: 1,
      title: title,
      routeContent: "<route></route>",
      templateId: "1",
    },
  },
});

export function getAllPagesMutation(projectId, count) {
  const response = {
    variables: {
      condition: {
        projectId: projectId,
      },
      orderBy: "NATURAL",
      indexFields: {
        last: count || 10,
      },
    },
  };
  return response;
}

export function updatePageMutation(pageTitle, PageID) {
  const response = {
    input: {
      pagePatch: {
        title: pageTitle,
      },
      pageId: PageID,
    },
  };
  return response;
}
