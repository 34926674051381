import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
  invalidCredential: null,
  authorities: cookies.get("loginAuthorities") || [],
  attributes: cookies.get("loginAttributes") || [],
  pkgAuthorities: cookies.get("packageAuthorities") || [],
  isAuthenticated: false,
  expireTokenID: null,
  isLoading: false,
  redirectURL: null,
  error: false,
  authenticateWebSocket: null,
};

const login = createSlice({
  name: "login",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    isLogin(state,action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.attributes=action.payload
    },
    
    isLogout(state, action) {
      state.expireTokenID = action.payload;
      state.isAuthenticated = false;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    getRedirectURL(state, action) {
      state.redirectURL = action.payload;
    },
    loginAuthorities(state, action) {
      state.authorities = action.payload === undefined ? [] : action.payload;
    },
    packageAuthorities(state, action) {
      state.pkgAuthorities = action.payload === undefined ? [] : action.payload;
    },
    loginAttributes(state, action) {
      state.attributes = action.payload === undefined ? [] : action.payload;
    },
    getAuthenticateWebSocket(state, action) {
      state.authenticateWebSocket =
        action.payload === undefined ? "" : action.payload;
    },
  },
});

export default login.reducer;
export const {
  startLoading,
  stopLoading,
  isLogin,
  hasError,
  isLogout,
  getRedirectURL,
  getAuthenticateWebSocket,
  loginAuthorities,
  packageAuthorities,
  loginAttributes,
} = login.actions;
