export function createScreenDataMutation(
  name,
  jsonData,
  pageUuid,
  projectUuid,
  pageIdInt
) {
  console.log(
    pageUuid,
    "pageUuid",
    projectUuid,
    "projectUuid",
    pageIdInt,
    "pageIdInt"
  );
  const response = {
    input: {
      screenDatum: {
        name: name,
        status: "1",
        json: jsonData,
        isDeleted: "0",
        projectId: projectUuid || "",
        pageId: pageUuid || "",
        pageIdInt: pageIdInt || "",
      },
    },
  };
  return response;
}

export function pageHtmlMutation(pageId) {
  const response = {
    variables: {
      pageId: pageId,
    },
  };
  return response;
}
export function allScreenComponents(last) {
  const response = {
    condition: {},
    orderBy: "NATURAL",
    indexFields: {
      last: last || 10,
    },
  };
  return response;
}

export function updateScreenDataMutation(elementID, jsonData) {
  const response = {
    input: {
      screenDatumPatch: {
        status: 1,
        json: jsonData,
      },
      id: elementID,
    },
  };
  return response;
}
