export const getProjectQuery = {
  query: `query allProjects($condition: ProjectCondition, $orderBy: ProjectOrderByEnum, $indexFields: IndexFields) {
    allProjects(condition: $condition, orderBy: $orderBy, indexFields: $indexFields) {
      totalCount
      edges {
        node {
          categoryId
          createdBy
          createdByUserId
          databasePrefId
          description
          estimatedUsers
          initialCreate
          isDeleted
          languagePrefId
          lastUpdated
          operatingTimeZones
          platformId
          projectBucket
          projectId
          id
          projectUserAssignmentId
          scopeId
          status
          subCategoryId
          themeId
          title
        }
      }
    }
  }
  `,
};

export const getProjectTeamDetailQuery = {
  query:`query allProjectUserAssignments($condition: ProjectUserAssignmentCondition, $orderBy: ProjectUserAssignmentsOrderBy, $indexFields: IndexFields) {
    allProjectUserAssignments(condition: $condition, orderBy: $orderBy, indexFields: $indexFields) {
      totalCount
      edges {
        node {
            projectByProjectId{
                id
                projectId
                title
            }
          projectId
          isRootUser
          intProjectId
          currentActive
          id
          status
          createdBy
          updatedBy
          userId
          userDto {
              id
              firstName
              lastName
              profileId
              profile{
                  name
                  id
              }
          }
        }
      }
    }
  }  
  `
}

export const createProjectQuery = {
  query: `mutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      project{
          projectId
          id
      }
    }
  }
  `,
};

export const updateProjectQuery = {
  query: `mutation($input: UpdateProjectInput!) {
    updateProjectByProjectId(input: $input) {
      project{
          projectId
          id
      }
    }
  }
    `,
};
export const getProjectUnassignedTeamQuery = {
  query:`query {
    users {
      id
      username
      firstName
      lastName
      email
      mobile
      root
      requiredAction
      emailVerified
      profile {
          id
          name
      }
    }
  }
  `
}

export const updateAsignedUserQuery = {
  query:`mutation($input: [UpdateProjectUserAssignmentByIdInput!]!) {
    updateProjectUserAssignmentById(input: $input) {
      updateResultDto {
          errorCode
          result {
              projectUserAssignment{
                  id
                  status
                  userId
                  projectId
              }
          }
      }
    }
  }
  
  `
}


export const createProjectUserQuery = {
  query: `mutation($input: [CreateProjectUserAssignmentInput!]!) {
    createProjectUserAssignment(input: $input) {
         createResultDto {
             errorCode
             result {
                 projectUserAssignment {
                     id
                     lastUpdated
                     status
                     projectId
                     userId
                 }
             }
         }
      
    }
  }`
};

export const deleteUserQuery = {
  query: `mutation ($id : ID!) {
    deleteUser(id: $id)
    {
        id
    }
  }`,
};




