import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
import ThemeProvider from "./theme";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
