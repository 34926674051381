import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  ProjectTeamList: [],
};

const projectTeam = createSlice({
  name: "projectTeam",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getProjectTeam(state, action) {
      state.ProjectTeamList = action.payload === undefined ? [] : action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export default projectTeam.reducer;
export const { getProjectTeam, startLoading, stopLoading } = projectTeam.actions;
