import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  databseList: [],
  databseName:"",
  tables: [],
  tableId: "",
  tableRelation: [],
  tableFields: null,
  UplodedData: [],
  tableViewId:'',
};

const dataBase = createSlice({
  name: "dataBase",
  initialState,
  reducers: {
    getDataBaseName(state, action) {
      state.databseList = action.payload;
    },
    getCurrentDatabseName(state, action){
     state.databseName = action.payload
    },
    getTableDetail(state, action) {
      state.tables = [...action.payload];
    },
    getTableID(state, action) {
      state.tableId = action.payload;
    },
    getTableFields(state, action) {
      state.tableDetail = { ...action.payload };
    },
    getTableRelation(state, action) {
      state.tableRelation = [...action.payload];
    },
    getUplodedData(state, action) {
      state.UplodedData = [...action.payload];
    },
    getTableViewID(state,action){
      state.tableViewId = action.payload
    },
  },
});

export default dataBase.reducer;
export const {
  getDataBaseName,
  getTableDetail,
  getTableID,
  getTableFields,
  getTableRelation,
  getUplodedData,
  getCurrentDatabseName,
  getTableViewID
} = dataBase.actions;
