import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { authProtectedPaths } from "../routes/path";
import Cookies from "universal-cookie";
import { ssoRedirectUrlService } from "../Middleware/action";
import { getFromUrl } from "../utils/customMethods";
const cookies = new Cookies();

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const data = cookies.get("token");
  const sso = getFromUrl("sso");
  const sig = getFromUrl("sig");
  const id = cookies.get("token");
  useEffect(() => {
    if (sso && sig && id) {
      ssoRedirectUrlService(sso, sig, id);
    }
  }, [sso, sig, id]);

  if (data && sig === null && sso === null) {
    return <Navigate to={authProtectedPaths.dashboard} />;
  }

  return <>{children}</>;
}
