import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useNavigate } from "react-router-dom";
import { getProjects } from "../redux/slice/projects";
import { getOrganizationUsers } from "../redux/slice/organizationUsers";
import { useSelector, dispatch } from "../redux/store";
import { isLogout } from "../redux/slice/login";
import { publicPaths } from "../routes/path";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const data = localStorage.getItem("refreshToken");
  const token = cookies.get("token");
  const navigate = useNavigate();
  const { expireTokenID } = useSelector((state) => state.login);
  useEffect(() => {
    if (expireTokenID) {
      navigate(publicPaths.login);
      dispatch(isLogout(null));
      dispatch(getProjects([]));
      dispatch(getOrganizationUsers([]));
    }
  }, [expireTokenID, navigate]);

  if (!data && !token) {
    return <Navigate to={publicPaths.login} />;
  }

  return <>{children}</>;
}
