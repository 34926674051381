export const createProjectMutation = (title, description, categoryId) => ({
  input: {
    project: {
      title,
      description,
      categoryId,
    },
  },
});
export function getProjectMutation(count) {
  const response = {
    variables: {
      condition: {},
      orderBy: "NATURAL",
      indexFields: {
        last: count | 10,
      },
    },
  };

  return response;
}

export function getProjectTeamDetailMutation(condition) {
  const response = {
    variables: {
      condition: condition,
      orderBy: "NATURAL",
      indexFields: {},
    },
  };

  return response;
}

export function getProjectUnassignedTeamMutation() {
  const response = {
    variables: {
      condition: {},
      orderBy: "NATURAL",
      indexFields: {
        last: 10,
      },
    },
  };

  return response;
}

export const updateProjectMutation = (dataToUpdate, projectId) => ({
  input: {
    projectPatch: { ...dataToUpdate },
    projectId,
  },
});

export const updateAssignedUsersaMutation = (userObj) => ({
  input: userObj,
});

export const deleteUserMutation = (userId) => ({
  id: userId,
});

export const createProjectUserMutation = (userObj) => ({
  input: userObj,
});
