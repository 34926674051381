import { defaultStyle } from "../config/Config";
import { pxToRem, responsiveFontSizes } from "../utils/getFontValue";

const FONT_PRIMARY = "Raleway, sans-serif";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  heading: {
    fontWeight: 600,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 24, md: 28, lg: 32 }),

    lineHeight: "130%",
    letterSpacing: "0.64px",
    color: "#1A1A1A",
    marginBottom: "32px",
    textAlign: "center",
  },
  subtitle1: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    color: "#1a1a1a",
  },
  title: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(35),
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(16),
    color: "grey",
  },
  subtitle2dark: {
    fontWeight: 700,
    lineHeight: 22 / 14,
    fontSize: pxToRem(16),
    color: "#1a1a1a",
  },
  strong: {
    fontWeight: 800,
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
    color: "#1a1a1a",
  },
  subtitle3: {
    fontSize: pxToRem(14),
    color: "grey",
  },
  subtitlelogin: {
    fontSize: pxToRem(12),
    color: "#666666",
    ...responsiveFontSizes({ sm: 12, md: 13, lg: 14 }),
  },
  subtitle5: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    color: "#666",
  },
  subtitle4: {
    fontWeight: 500,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
    color: "grey",
  },
  subtitle6: {
    fontSize: pxToRem(14),
    color: "#1a1a1a",
    fontWeight: "500",
  },
  subtitleCapital: {
    fontSize: pxToRem(14),
    color: "#1a1a1a",
    fontWeight: "500",
    textTransform:"capitalize"
  },
  size16weight500: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  size14weight500: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontWeight: 600,
    fontSize: pxToRem(15),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    color: "#1a1a1a",
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 500,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
  radio: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  secondaryButton: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    background: "#fff",
    border: "1px solid #ec673d",
    borderRadius: "5px",
  },
  sideBarTitle: {
    fontWeight: 600,
    fontSize: pxToRem(16),
    textTransform: "capitalize",
  },
  sideBarTitleCursor: {
    fontWeight: 600,
    fontSize: pxToRem(16),
    textTransform: "capitalize",
    cursor: "pointer",

  },
  categoryTitle: {
    fontSize: pxToRem(13),
    fontWeight: 600,
    lineHeight: "20.8px",
    letterSpacing: "0.37px",
    margin: "0 -4px",
  },
  tabText: {
    fontSize: pxToRem(10),
    fontWeight: 600,
    lineHeight: "11.74px",
  },
  tabTextGrey: {
    fontSize: pxToRem(10),
    fontWeight: 600,
    lineHeight: "14px",
    color: "grey",
  },
  canvasPageName: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: "18.78px",
  },
  teammember: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: "16.44px",
    color: "#1A1A1A",
  },
  upgrade: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    cursor: "pointer",
    color: defaultStyle.buttonColor,
  },
  upgrade14: {
    lineHeight: 1.1,
    fontSize: pxToRem(14),
    cursor: "pointer",
    color: defaultStyle.buttonColor,
  },
  modalTitle:{
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    flexGrow: 1,
    textTransform: "capitalize",
    color: "#666666",

  }
};

export default typography;
