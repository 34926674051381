import PropTypes from "prop-types";
import { useMemo } from "react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { defaultStyle } from "../config/Config";
import typography from "./typography";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  //   const { themeMode, themeDirection } = useSettings();
  //   const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: {
          main: defaultStyle.defaultColor,
        },
        secondary: {
          main: defaultStyle.secondaryColor,
        },
        info: {
          main: defaultStyle.secondaryHoverColor,
        },
        synstaOrange: {
          main: defaultStyle.synstaoOrangeColor,
        },
      },

      //   palette: isLight ? palette.light : palette.dark,
      typography,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  //   theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
