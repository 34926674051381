import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  organizationUsers: []
};

const organizationUsers = createSlice({
  name: "organizationUsers",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getOrganizationUsers(state, action) {
      state.organizationUsers = action.payload === undefined ? [] : action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export default organizationUsers.reducer;
export const { getOrganizationUsers, startLoading, stopLoading } = organizationUsers.actions;
