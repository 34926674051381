import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successMessage: "",
  errorMessage: "",
  responseStatus: null,
  isLoading: false,
  isButtonLoading: false,
  error: false,
};

const response = createSlice({
  name: "response",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    startButtonLoading(state) {
      state.isButtonLoading = true;
    },
    getResponseStatus(state, action) {
      state.responseStatus = action.payload;
    },
    stopButtonLoading(state) {
      state.isButtonLoading = false;
    },
  },
});

export default response.reducer;
export const {
  startLoading,
  stopLoading,
  hasError,
  startButtonLoading,
  stopButtonLoading,
  getResponseStatus,
} = response.actions;
