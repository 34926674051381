export const convertArrayFormHtml = (editor, componentId) => {
  let arrayofComponent = [];
  const component = editor?.getWrapper();
  const extractOpeningTag = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    const div = tempDiv.firstChild;
    if (div?.children && div.children.length > 0) {
      const match = htmlString.match(/<(\w+)[^>]*>/);
      const openingTag = match ? match[0] : htmlString;
      const closingTag = match ? `</${match[1]}>` : htmlString;
      return `${openingTag + closingTag}`;
    } else {
      return `${htmlString}`;
    }
  };
  if (componentId) {
    if (componentId === component.getId()) {
      arrayofComponent.push({
        html: component.toHTML(),
        name: component?.getName(),
        id: component.getId(),
        parent: null,
        position: component?.index(),
        body: null,
      });
      component.forEachChild((child) => {
        arrayofComponent.push({
          html: child.toHTML(),
          name: child?.getName(),
          id: child.getId(),
          parent: child?.parent()?.getId(),
          position: child?.index(),
          body: child.parent().getName(),
        });
      });
    } else {
      component.forEachChild((child) => {
        if (child.getId() === componentId) {
          arrayofComponent.push({
            html: child.toHTML(),
            name: child?.getName(),
            id: child.getId(),
            parent: child?.parent()?.getId(),
            position: child?.index(),
            body: child.parent().getName(),
          });
          child?.forEachChild((nst) => {
            arrayofComponent.push({
              html: nst.toHTML(),
              name: nst?.getName(),
              id: nst?.getId(),
              parent: nst?.parent()?.getId(),
              position: nst?.index(),
            });
          });
        }
      });
    }
  } else {
    component.forEachChild((child) => {
      arrayofComponent.push({
        html: child.toHTML(),
        name: child?.getName(),
        id: child.getId(),
        parent: child?.parent()?.getId(),
        position: child?.index(),
        body: child.parent().getName(),
      });
    });
  }
  let ParentChldComponents = [];
  arrayofComponent.forEach((item) => {
    if (extractOpeningTag(item.html, item.name).includes("<")) {
      ParentChldComponents.push({
        html: extractOpeningTag(item.html, item.name),
        parent: item.parent,
        position: item.position !== undefined ? item.position : "*",
        id: item?.id,
        body: item.body,
      });
    }
  });

  return ParentChldComponents;
};

export const convertHtmlFromArray = (listOfItems) => {
  let htmlhirerch = [];
  listOfItems.forEach((item) => {
    const childs = listOfItems?.filter(
      (nestedchidl) => nestedchidl?.parentId === item?.componentId
    );
    const sortedData = childs.sort((a, b) => {
      const positionA =
        typeof a.settings === "object"
          ? a.settings.position
          : JSON.parse(a.settings).position;
      const positionB =
        typeof a.settings === "object"
          ? b.settings.position
          : JSON.parse(b.settings).position;
      return positionA - positionB;
    });
    const childhtml = sortedData?.map((lis) => lis.componentId);
    htmlhirerch.push({
      componentId: item.componentId,
      parentId: item.parentId,
      html: item.html,
      child: [...childhtml],
    });
  });
  const htmlOutput = generateHTML(htmlhirerch);
  return htmlOutput;
};

const generateHTML = (data) => {
  const nodesMap = {};
  data.forEach((node) => (nodesMap[node.componentId] = node));
  function generateNodeHTML(componentId) {
    const node = nodesMap[componentId];
    // eslint-disable-next-line
    let html = node.html;

    if (node.child || node.child.length > 0) {
      const childrenHTML = node.child
        .map((childId, index) => {
          if (nodesMap[childId]) {
            return generateNodeHTML(childId);
          } else {
            const vgk = data?.find((ned) => ned.componentId === childId);
            return vgk?.html || `${index}`;
          }
        })
        .join("");
      const closingTagIndex = html?.indexOf(">");
      html =
        html.slice(0, closingTagIndex + 1) +
        childrenHTML +
        html.slice(closingTagIndex + 1);
    }

    return html;
  }

  const rootNode = data.find((node) => node.parentId === null);
  return generateNodeHTML(
    rootNode ? rootNode.componentId : data[0].componentId
  );
};

export const isPageIdExsistInUrl = () => {
  const url = window?.location?.href;
  const isWordExsist = url.includes("pageId");
  return isWordExsist;
};

export const iscomponentIDExsistInUrl = () => {
  const url = window?.location?.href;
  const isWordExsist = url.includes("componentID");
  return isWordExsist;
};

export const getFromUrl = (value) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const valueFromUrl = url.searchParams.get(`${value}`);
  return valueFromUrl;
};

export const formatDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedDate = `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()} ${formattedHours}:${formattedMinutes}${ampm}`;
  return formattedDate;
};

const EpochSecondConverter = (epochSeconds) => {
  const date = new Date(epochSeconds * 1000);

  function formatDay(date) {
    const day = new Date(date).getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }
    return `${day}${suffix}`;
  }
  const formattedDay = formatDay(date);
  return formattedDay;
};
export default EpochSecondConverter;

export const convertToHHMMSS = (val) => {
  const secNum = parseInt(val, 10);
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  let time;
  if (hours === "00") {
    time = minutes + ":" + seconds;
  } else {
    time = hours + ":" + minutes + ":" + seconds;
  }

  return time;
};

export const convertToOriginalValue = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

export const clearCanvaForBocks = (editor) => {
  const existingComponents = editor.DomComponents.getComponents();
  localStorage.setItem("savedComponents", JSON.stringify(existingComponents));
  localStorage.setItem("savedStyle", editor?.getCss());
  editor.setComponents("");
};
export const setComponentOnCanva = (editor) => {
  const savedComponents = localStorage.getItem("savedComponents");
  const savedStyle = localStorage.getItem("savedStyle");
  editor?.DomComponents?.setComponents(JSON.parse(savedComponents));
  editor.setStyle(savedStyle);
  localStorage.removeItem("savedComponents");
  localStorage.removeItem("savedStyle");
};

export const generatePieChartHTML = (data) => {
  const total = data.reduce((sum, item) => sum + item.data, 0);
  const colors = ["rgb(76, 175, 80)", "rgb(33, 150, 243)", "rgb(255, 152, 0)"];

  const getSlice = (start, end, color) => `${color} ${start}% ${end}%`;

  const styles = `
      .pie-chart {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background: conic-gradient(
              ${data
                .map((item, index) => {
                  const start = (
                    (data
                      .slice(0, index)
                      .reduce((sum, el) => sum + el.data, 0) /
                      total) *
                    100
                  ).toFixed(2);
                  const end = (
                    (data
                      .slice(0, index + 1)
                      .reduce((sum, el) => sum + el.data, 0) /
                      total) *
                    100
                  ).toFixed(2);
                  return getSlice(start, end, colors[index]);
                })
                .join(",")}
          );
          position: relative;
      }

      .label {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: bold;
      }

      .legend {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
      }

      .legend-item {
          display: flex;
          align-items: center;
          margin: 5px 0;
      }

      .legend-color {
          width: 15px;
          height: 15px;
          margin-right: 10px;
      }
  `;

  const legendItems = data
    .map(
      (item, index) => `
      <div class="legend-item">
          <div class="legend-color" style="background-color: ${colors[index]}"></div>
          <span>${item.label}</span>
      </div>
  `
    )
    .join("");

  const html = `
      <style>${styles}</style>
      <div class="pie-chart">
          <div class="label">Pie Chart</div>
      </div>
      <div class="legend">${legendItems}</div>
  `;

  return html;
};

export const CancelledSubscriptionDate = (epoch) => {
  const date = new Date(epoch * 1000);

  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, "0")}${ampm}`;

  const fullDateTime = `${formattedDate} at ${formattedTime}`;

  return fullDateTime;
};

export const removefromLocalstorage = () => {
  localStorage.removeItem("database");
  localStorage.removeItem("socketSessionID");
  localStorage.removeItem("joinedBy");
  localStorage.removeItem("instanceId");
  localStorage.removeItem("otherMembers");
  localStorage.removeItem("receiverUserSession");
  localStorage.removeItem("senderID");
  localStorage.removeItem("session");
  localStorage.removeItem("gjsProject");
  localStorage.removeItem("followers");
};
