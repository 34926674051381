import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPageLoading: false,
    allPages: [],
    totalPages:10,
    createPageId: null
}

const allPages = createSlice({
    name: "allPages",
    initialState,
    reducers: {
        startPageLoading(state) {
            state.isPageLoading = true;
        },
        getAllPage(state, action) {
            state.allPages = action.payload === undefined ? [] : action.payload;
        },
        gettotalPagesNoumber(state,action){
         state.totalPages = action.payload
        },
        createPageResponse(state, action) {
            state.createPageId = action.payload
        },
        StopPageLoading(state) {
            state.isPageLoading = false;
        },
    }
})

export default allPages.reducer;
export const { getAllPage, createPageResponse, startPageLoading, StopPageLoading } = allPages.actions;