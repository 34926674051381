import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { reducer as toastrReducer } from "react-redux-toastr";
import {
  login,
  paymentFlowSlice,
  projects,
  projectTeam,
  organizationUsers,
  teamMember,
  permission,
  response,
  allPages,
  allScreenData,
  workspace,
  dataBase,
  api,
  bpnm,
} from "./slice";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const rootReducer = combineReducers({
  login,
  projects,
  projectTeam,
  organizationUsers,
  teamMember,
  permission,
  response,
  allPages,
  workspace,
  allScreenData,
  paymentFlowSlice,
  dataBase,
  api,
  bpnm,
  toastr: toastrReducer,
});
export { rootPersistConfig, rootReducer };
