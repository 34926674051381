// errorCodes.js

export const errorCodes = {
  OK: { code: 200, series: "SUCCESSFUL", description: "OK" },
  CREATED: { code: 201, series: "SUCCESSFUL", description: "Created" },
  backend_error: {
    code: "backend_error",
    description: "Encountered a backend error. Please try again.",
  },
  not_authorized: {
    code: "not_authorized",
    description: "You are not authorized.",
  },
  invalid_refresh_token: {
    code : "invalid_refresh_token",
    description: "Something went wrong. Please logout and login again",
  },
  refreshTokenExpired: {
    code: "Variable 'refreshToken' has an invalid value: Variable 'refreshToken' has coerced Null value for NonNull type 'String!'",
    description: "Something went wrong. Please logout and login again",
  },
  InvalidInput : {
    code : "typeMismatch.UUID,typeMismatch",
    description : "Something went wrong. Please logout and login again"
  },
  email_exists: {
    code: "email_exists",
    description: "This email already exists.",
  },
  org_not_found: {
    code: "org_not_found",
    description: "Organization not found.",
  },
  org_levels_not_found: {
    code: "org_levels_not_found",
    description: "Organization levels not found.",
  },
  user_not_found: { code: "user_not_found", description: "User not found." },
  user_name_already_exists: {
    code: "user_name_already_exists",
    description: "Username already exists.",
  },
  user_not_active: {
    code: "user_not_active",
    description: "User is not active.",
  },
  reset_password_required: {
    code: "reset_password_required",
    description: "Password reset required.",
  },
  root_user_exists: {
    code: "root_user_exists",
    description: "Root user already exists.",
  },
  profile_not_found: {
    code: "profile_not_found",
    description: "Profile not found.",
  },
  profile_id_required: {
    code: "profile_id_required",
    description: "Profile ID is required.",
  },
  profile_exists: {
    code: "profile_exists",
    description: "Profile already exists.",
  },
  profile_has_assigned_users: {
    code: "profile_has_assigned_users",
    description: "This profile has assigned users.",
  },
  profile_reserved: {
    code: "profile_reserved",
    description: "This profile is reserved.",
  },
  idm_profile_not_found: {
    code: "idm_profile_not_found",
    description: "IDM profile not found.",
  },
  idm_profile_exists: {
    code: "idm_profile_exists",
    description: "IDM profile already exists.",
  },
  root_profile_exists: {
    code: "root_profile_exists",
    description: "Root profile already exists.",
  },
  permission_not_found: {
    code: "permission_not_found",
    description: "Permission not found.",
  },
  idm_permission_not_found: {
    code: "idm_permission_not_found",
    description: "IDM permission not found.",
  },
  wrong_username_password: {
    code: "wrong_username_password",
    description: "Incorrect username or password.",
  },
  old_new_password_are_equal: {
    code: "old_new_password_are_equal",
    description: "New password cannot be the same as the old password.",
  },
  password_reset_failed: {
    code: "password_reset_failed",
    description: "Failed to reset the password.",
  },
  logout_failed: { code: "logout_failed", description: "Failed to log out." },
  otp_not_found: { code: "otp_not_found", description: "OTP not found." },
  otp_expired: { code: "otp_expired", description: "OTP has expired." },
  otp_not_matched: {
    code: "otp_not_matched",
    description: "OTP does not match.",
  },
  validation_failed: {
    code: "validation_failed",
    description: "Validation failed.",
  },
  already_exist: { code: "already_exist", description: "Already exists." },
  not_exist: { code: "not_exist", description: "Does not exist." },
  id_is_required: { code: "id_is_required", description: "ID is required." },
  name_is_required: {
    code: "name_is_required",
    description: "Name is required.",
  },
  package_id_is_required: {
    code: "package_id_is_required",
    description: "Package ID is required.",
  },
  def_id_is_required: {
    code: "def_id_is_required",
    description: "Definition ID is required.",
  },
  name_already_exist: {
    code: "name_already_exist",
    description: "Name already exists.",
  },
  root_already_exist: {
    code: "root_already_exist",
    description: "Root already exists.",
  },
  org_not_exist: {
    code: "org_not_exist",
    description: "Organization does not exist.",
  },
  org_def_not_exist: {
    code: "org_def_not_exist",
    description: "Organization definition does not exist.",
  },
  org_def_level_exist: {
    code: "org_def_level_exist",
    description: "Organization definition level already exists.",
  },
  org_level_not_exist: {
    code: "org_level_not_exist",
    description: "Organization level does not exist.",
  },
  org_level_exist: {
    code: "org_level_exist",
    description: "Organization level already exists.",
  },
  parent_not_exist: {
    code: "parent_not_exist",
    description: "Parent does not exist.",
  },
};


const getErrorDescription = (errorCode) =>
  errorCodes[errorCode].description || "Unknown error";

const ErrorHandler = (errorCode) => {
  const errorDescription = getErrorDescription(errorCode);
  return errorDescription;
};

export default ErrorHandler;
