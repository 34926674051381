export const refreshTokenQuery = {
  query: `mutation ($refreshToken: String!){
    refreshToken(
        refreshToken: $refreshToken 
       ) {
        token 
        expiresIn 
        refreshExpiresIn 
        tokenType 
        refreshToken 
        authorities
        pkgAuthorities
        attributes
   }
}`,
};

export const loginQuery = {
  query: `mutation ($username: String!, $password: String!, $sso: String, $sig: String) {login(username: $username password: $password sso: $sso sig:$sig) {
    token 
    expiresIn 
    refreshExpiresIn 
    tokenType 
    refreshToken 
    authorities
    redirectUrl
    pkgAuthorities
    attributes
  }
}`,
};

export const ssoRedirectUrlQuery = {
  query: `mutation {
    getSsoRedirectUrl(
        sso:"",
        sig:"",
        id:""
      ) 
  }`,
};

export const registerQuery = {
  query: `mutation ($request: RegisterRequest!) {
      register(
          request: $request
        ){
            id
        }
    }`,
};
export const createCustomerQuery = {
  query: `mutation ($request: CustomerCreateParams!) {
  createCustomer(
      request: $request
    ){
        id
        customerId
        customerName
    }
}`,

}
export const getCustomerQuery = {
  query: `query  {
  getCustomer{
        id
        customerId
        customerName
    }
}`,

}

export const createProfileQuery = {
  query: `mutation ($profile: ProfileCreateRequest!) {
  createProfile(
      request: $profile
    ){
        id
    }
}`,

}
export const getProfileQuery = {
  query: `query {
  profiles {
   id
   name
   permissionIds
  }
}`,

}
export const sendotpQuery = (email) => ({
  query: `
        query {
          userEmailExists(email: "${email}")
        }
      `,
});

export const generateOtpQuery = (email) => ({
  query: `
        mutation {
          generateOtp(email: "${email}")
        }
      `,
});

export const logoutQuery = {
  query: `mutation ($tokenId: ID!) {
    logoutUserByTokenId(
        tokenId : $tokenId
       ) {
           id
       }
}`,
};

export const emailExistsQuery = {
  query: `query ($email: String!) {
    userEmailExists(
        email: $email
      )
  }`,
};



export const completeResetPasswordQuery = {
  query: `mutation ($email: String!, $otp: String!, $newPassword: String!) {
    completeResetPassword(
        email: $email 
        otp: $otp 
        newPassword: $newPassword
       ) {
       id
   }
  }`,
};

export const changePasswordQuery = {
  query: `mutation ($username: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(
        username: $username 
        oldPassword: $oldPassword
        newPassword: $newPassword
      ) {
      id
  }
}`,
};

export const createTeamMemberQuery = {
  query: `mutation ($request: UserCreateRequest!) {
    createUser(
        request: $request
      ){
          id
      }
  }
  `,
};

export const updateTeamMemberQuery = (memberData) => ({
  query: `mutation {
    updateUser(request: 
      {
            id: "${memberData.id}",
             firstName: "${memberData.firstName}",
             lastName: "${memberData.lastName}",
             email: "${memberData.email}",
             mobile: "${memberData.mobile}",
             profileId: "${memberData.profileId}",
      })
    {
        id
    }
  }
  `,
});

export const getTeamMemberDetailsQuery = (id) => ({
  query: `query {
    userById(id: "${id}") {
      id
    username
    firstName
    lastName
    email
    mobile
    profile {
        id
        name
    } 
 }
}
  `,
});

export const socketQuery = {
  query: ` mutation  {
    authenticateWebSocket
}
  `,
};
