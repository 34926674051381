import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bpnmList: [],
  bpnmScreen: false,
};

const bpnm = createSlice({
  name: "bpnm",
  initialState,
  reducers: {
    getBpnmScreen(state, action) {
      state.bpnmScreen = action.payload;
    },
  },
});

export default bpnm.reducer;
export const { getBpnmScreen } = bpnm.actions;
