import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  teamMember: "",
};

const teamMember = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getTeamMember(state, action) {
      state.teamMember = action.payload === undefined ? [] : action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

export default teamMember.reducer;
export const { getTeamMember, startLoading, stopLoading } = teamMember.actions;
