export const publicPaths = {
  login: "/login",
  register: "/register",
  DashboardHome: "/home",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword",
};

export const authProtectedPaths = {
  home: "/home",
  dashboard: "/dashboard",
  ManagePermissions: "managepermissions",
  OrganisationStructure: "organisationstructure",
  ManageTeam: "manageteam",
  MyAccount: "myaccount",
  SignOut: "signout",
  Settings: "/dashboard/settings",
  ChoosePlan: "/dashboard/choosePlan",
  Workspace: "/dashboard/builder?",
  bpnmworkspace: "/dashboard/bpnm?",
  dmnworkspace: "/dashboard/dmn?",
  ManageUserProjects: "/dashboard/manageUserProjects?",
};
