import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reusableComponent: [],
  BlockName: null,
  BlockCategory: null,
  followedUserId: "",
  UserCursorDetail: null,
  commentAdd: false,
  ListOfComments: [],
  asset: null,
  assteStyle: {
    brightness: 1.0,
    contrast: 1,
    saturate: 1,
    grayscale: "",
    Opacity: 100,
    blur: 0,
  },
  listOfAssets: [],
};

const workspace = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    getBlockName(state, action) {
      state.BlockName = action.payload;
    },
    getReusableComponent(state, action) {
      state.reusableComponent = action.payload || [];
    },
    getFollowUserId(state, action) {
      state.followedUserId = action.payload;
    },
    getcommentAdd(state, action) {
      state.commentAdd = action.payload;
    },
    getListOfComments(state, action) {
      state.ListOfComments = action.payload
        ? [...state.ListOfComments, ...action.payload]
        : [...state.ListOfComments];
    },
    getAssetDetail(state, action) {
      state.asset = action.payload;
    },
    getAssetStyle(state, action) {
      switch (action.payload.action) {
        case "brightness":
          state.assteStyle.brightness = action.payload.value;
          break;
        case "contrast":
          state.assteStyle.contrast = action.payload.value;
          break;
        case "saturate":
          state.assteStyle.saturate = action.payload.value;
          break;
        case "grayscale":
          state.assteStyle.grayscale = action.payload.value;
          break;
        case "Opacity":
          state.assteStyle.Opacity = action.payload.value;
          break;
        case "blur":
          state.assteStyle.blur = action.payload.value;
          break;

        default:
          break;
      }
    },
    getAssetsList(state, action) {
      state.listOfAssets = [...state.listOfAssets, action.payload];
    },
    getUserCursorDetail(state, action) {
      state.UserCursorDetail = action.payload || null;
    },
    getBlockCategory(state, action) {
      state.BlockCategory = action.payload;
    },
  },
});

export default workspace.reducer;
export const {
  getBlockName,
  getBlockCategory,
  getReusableComponent,
  getFollowUserId,
  getcommentAdd,
  getListOfComments,
  getAssetDetail,
  getAssetStyle,
  getAssetsList,
  getUserCursorDetail,
} = workspace.actions;
