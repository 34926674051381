export const getPageHtmlQuery = {
  query: `query ($pageId: ID!) {
    getComponents(
        pageId: $pageId
      ) {
          html
          rootId
          style
      }
  }`,
};
export const getAllScreenDataQuery = {
  query: `query allScreenData($condition: ScreenDataCondition, $orderBy: ScreenDataOrderBy, $indexFields: IndexFields) {
    allScreenData(condition: $condition, orderBy: $orderBy, indexFields: $indexFields) {
      totalCount
      edges {
        node {
            id
            name
            createdAt
            createdBy
            isDeleted
            json
            pageId
            projectId
            status
            updatedBy
            updatedAt          
        }
      }
    }
  }`,
};

export const createScreenDataQuery = {
  query: `mutation($input: CreateScreenDatumInput!) {
    createScreenDatum(input: $input) {
      screenDatum{
          id
            createdAt
            isDeleted
            json
            pageId
            projectId
            status
            updatedAt  
      }
    }
  }`,
};

export const updateScreenDataQuery = {
  query: `mutation($input: UpdateScreenDataByIdInput!) {
    updateScreenDatumById(input: $input) {
      screenDatum{
            id
            createdAt
            createdBy
            isDeleted
            json
            pageId
            projectId
            status
            updatedBy
            updatedAt 
      }
    }
  }`,
};
