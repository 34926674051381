export const addManagePermissionQuery = {
  query: `mutation ($request: UserCreateRequest!) {
            createUser(
                request: $request
              ){
                  id
              }
          }`,
};

export const updateProfileQuery = {
  query: `mutation ($request: ProfileUpdateRequest!) {
            updateProfile(
                request: $request,
              ){
                  id
              }
          }`,
};

export const createProfileQuery = {
  query: `mutation ($profile: ProfileCreateRequest!) {
          createProfile(
            request: $profile
            ){
                id
            }
        }`,
};

export const getProfileQuery = {
  query: `query { profiles {id name permissionIds}}`,
};


