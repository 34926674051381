import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apiList: [],
  currentApi:null
};

const api = createSlice({
  name: "api",
  initialState,
  reducers: {
    getListOfApi(state, action) {
      state.apiList = [...action.payload];
    },
    editApi(state, action){
      state.currentApi = action.payload
    }
  },
});

export default api.reducer;
export const { getListOfApi,editApi } = api.actions;
